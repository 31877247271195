<template>
  <div>
    <div style="margin-bottom: 15px">
      <van-search
        v-model="searchParamValue"
        show-action
        label="标题名"
        placeholder="请输入标题名关键词"
        @search="onSearch"
        @clear="onSearchClear"
      >
        <template #action>
          <div @click="showPopup" style="color: #1989fa">筛选</div>
        </template>
      </van-search>
    </div>

    <DebtList :orderId="1" :searchParam="searchParam" ref="DebtList"></DebtList>
    <van-popup position="bottom" :style="{ height: '90%' }" v-model="orderInfoPopupShow">
      <van-field v-model="username" label="标题名" placeholder="请输入标题名"/>
      <van-field name="radio" label="状态">
        <template #input>
          <van-radio-group v-model="status" direction="horizontal">
            <van-radio name="-1">未支付</van-radio>
            <van-radio name="0">已支付</van-radio>
            <van-radio name="1">结账</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="checkbox" label="是否付清">
        <template #input>
          <van-checkbox v-model="paidBoolean" shape="square"/>
        </template>
      </van-field>
      <van-field name="checkbox" label="未结账">
        <template #input>
          <van-checkbox v-model="debtBoolean" shape="square"/>
        </template>
      </van-field>
      <van-field v-model="date" label="订单日期" placeholder="选择订单日期" @click="showCalendar = true"/>
      <van-calendar v-model="showCalendar" :show-confirm="false" :min-date="minDate" :max-date="maxDate"
                    @confirm="onConfirm"/>
      <div style="text-align: center;">
        <van-button style="margin-top: 20px; width: 80%" size="large" type="primary" @click="search">搜索</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {Button, Calendar, Cascader, Cell, Checkbox, Field, Popup, Radio, RadioGroup, Search} from 'vant';
import * as getDataService from "@/api/serverData/getServerData";
import DebtList from "@/view/order/debtList";
import $store from "@/store";


export default {

  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Cascader.name]: Cascader,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Search.name]: Search,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    DebtList
  },

  data() {
    return {
      debtBoolean: true,
      paidBoolean: null,
      status: null,
      searchParamValue: '',
      searchParam: {
        debtBoolean: true,
        paidBoolean: null,
        status: null
      },
      username: '',
      orderInfoPopupShow: false,
      text: '',
      date: '',
      showCalendar: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
    };
  },
  mounted() {
    // this.onSearchClear()
  },
  created() {
    if (this.$route.query && this.$route.query.userId) {
      this.searchParam.userId = this.$route.query.userId
      this.onResetSearchParam()
    } else {
      console.log('$store.state.order.searchParamDebt', $store.state.order.searchParamDebt)
      console.log('$store.state.order.searchParamDebt', $store.state.order.searchParamDebt.debtBoolean)
      this.searchParam = $store.state.order.searchParamDebt
      if (!$store.state.order.searchParamDebt.debtBoolean) {
        this.debtBoolean = true
        this.searchParam.debtBoolean = true
      } else {
        this.debtBoolean = $store.state.order.searchParamDebt.debtBoolean
      }
      this.searchParam.userId = null
      this.searchParamValue = $store.state.order.searchParamDebt.searchParamValue
      this.status = this.searchParam.status
      this.paidBoolean = this.searchParam.paidBoolean
      this.date = this.searchParam.orderDatetime
    }
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    onResetSearchParam() {
      this.searchParam.username = null
      this.searchParam.orderDatetime = null
      this.searchParam.status = null
      this.searchParam.paidBoolean = null
      this.searchParam.debtBoolean = true
      this.searchParam.searchParamValue = ''
      $store.commit('SET_SEARCH_PARAM_DEBT', this.searchParam)
      this.date = ''
      this.status = null
      this.paidBoolean = null
      this.debtBoolean = true
      this.searchParamValue = ''
      this.username = ''
    },
    onSearchClear() {
      this.onResetSearchParam()
      this.$refs.DebtList.onRefresh();
    },
    onSearch() {
      this.searchParam.username = this.searchParamValue
      this.username = this.searchParamValue
      this.searchParam.searchParamValue = this.searchParamValue
      $store.commit('SET_SEARCH_PARAM_DEBT', this.searchParam)
      this.$refs.DebtList.onRefresh();
    },
    search() {
      this.searchParam.username = this.username;
      this.searchParam.status = this.status
      this.searchParam.paidBoolean = this.paidBoolean
      this.searchParam.debtBoolean = this.debtBoolean
      this.searchParam.searchParamValue = '多条件筛选'
      this.searchParamValue = '多条件筛选'
      $store.commit('SET_SEARCH_PARAM_DEBT', this.searchParam)
      this.$refs.DebtList.onRefresh();
      this.orderInfoPopupShow = false
    },
    showPopup() {
      this.orderInfoPopupShow = true
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.date = this.formatDate(date);
      this.searchParam.orderDatetime = this.formatDate(date);
    },
  }
};
</script>

<style lang="less">
.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}
</style>
