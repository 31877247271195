<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <div v-for="item in dataList" :key="item.id" :name="item.id">
          <div class="van-coupon">
            <div>
              <van-row>
                <van-col span="12" >
                  <div style="padding-left: 3px; padding-top: 2px; text-align: left;">
                    <van-tag v-if="item.paid === 1" class="order-tag-margin-left" type="primary">付清</van-tag>
                    <van-tag v-if="item.status === 1" class="order-tag-margin-left" type="success">结账</van-tag>
                    <van-tag v-if="item.status === 0 || item.status === -1" class="order-tag-margin-left" type="danger">欠账</van-tag>
                  </div>
                </van-col>
                <van-col span="12">
                  <div style="padding-right: 3px; padding-top: 2px; text-align: right;">
                    <van-tag v-if="item.isMerge == 1" round type="warning">被合并</van-tag>
                    <van-tag v-if="item.type == 0" round type="primary" style="margin-left: 2px;">单一账单</van-tag>
                    <van-tag v-if="item.type == 1" round type="success" style="margin-left: 2px;">合并账单</van-tag>
                  </div>
                </van-col>
              </van-row>
            </div>
            <div class="van-coupon__content" style="padding: 0px;">
              <div class="van-coupon__head">
                <h2 class="van-coupon__amount" style="margin-bottom: 0px;">{{ item.totalPrice }}<span>元</span></h2>
              </div>
              <div class="van-coupon__body" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <div class="van-coupon__name" style="margin-bottom: 3px;">{{ item.realName }}</div>
                <div class="van-coupon__valid" style="margin-bottom: 5px; font-size: 14px;">{{ item.createTime }}</div>
                <div class="van-coupon__valid" style="font-size: 14px;">已支付{{ item.payPrice }}元
                  <span v-if="item.isMerge != 1 && item.status != 1">还需支付
                        <span style="color: #ee0a24;font-weight: 500; font-size: 18px;">{{ item.debtPrice }}</span>元
                      </span>
                </div>
              </div>
            </div>
            <div class="van-coupon__description">
              <van-row>
                <van-col span="16">
                  <van-notice-bar
                      left-icon="volume-o" :scrollable="false" style="height: 25px;"
                  >
                    <van-swipe
                        vertical
                        class="notice-swipe"
                        :autoplay="3000"
                        :show-indicators="false"
                    >
                      <van-swipe-item v-if="item.mark != ''">{{ item.mark }}</van-swipe-item>
                      <van-swipe-item v-if="item.isMerge == 1">被合并的欠账单，无法操作</van-swipe-item>
                    </van-swipe>
                  </van-notice-bar>
                </van-col>
                <van-col span="8">
                  <div style="text-align: right;">
                    <van-button v-if="item.isMerge != 1" type="danger" size="mini" @click="deleteDebt(item)">删
                    </van-button>
                    <van-button v-if="item.isMerge != 1 && item.status != 1" type="primary" size="mini"
                                @click="submitForPayOrder(item)">付
                    </van-button>
                    <van-button v-if="item.isMerge != 1 && item.status === 1" type="primary" size="mini"
                                @click="rollBack(item)">回
                    </van-button>
                    <van-button type="primary" size="mini" @click="toDebtInfo(item)">详</van-button>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="orderInfoPopupShow">
      <div v-for="item in orderInfos" :key="item.id" :name="item.id" style="width: 300px">
        <van-card
            :price="(Number(item.price) * 100 / 100).toFixed(2)"
            :desc="item.sku"
            :title="item.storeName"
            :thumb="item.image"
            :num="item.number"
        >
        </van-card>
      </div>
    </van-popup>
    <van-popup v-model="isPaymentShow" position="bottom" :style="{ height: '40%' }">
      <van-form @submit="onPayForDebt">
        <van-cell-group inset>
          <van-field name="radio" label="支付">
            <template #input>
              <van-radio-group v-model="paymentStatusChecked" direction="horizontal" @change="onPaymentStatusChange">
                <van-radio name="0">欠账</van-radio>
                <van-radio name="1">付清</van-radio>
                <van-radio name="2">结账</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field name="radio" label="支付方式">
            <template #input>
              <van-radio-group v-model="paymentTypeChecked" direction="horizontal">
                <van-radio name="微信" style="margin-top: 2px;">微信</van-radio>
                <van-radio name="现金" style="margin-top: 2px;">现金</van-radio>
                <van-radio name="支付宝" style="margin-top: 2px;">支付宝</van-radio>
                <van-radio name="银行卡" style="margin-top: 2px;">银行卡</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
            订单金额：{{ totalPrice }}，已支付{{ paymentPrice }}元，需支付{{ totalPrice - paymentPrice }}元
          </van-notice-bar>
          <van-field v-model="payPrice"
                     size="small" label="支付金额" rows="1" type="number" style="margin-bottom: 3px;"/>
          <van-field
              v-model="mark"
              rows="2"
              autosize
              label="留言"
              type="textarea"
              maxlength="50"
              placeholder="请输入留言"
              show-word-limit
          />
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
  </div>
</template>

<script>
import {
  Button,
  Cell,
  CellGroup,
  CheckboxGroup,
  Col,
  ContactCard,
  CouponCell,
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  Image,
  ImagePreview,
  List,
  Loading,
  NoticeBar,
  Notify,
  Popup,
  PullRefresh,
  Radio,
  RadioGroup,
  Row,
  Swipe,
  SwipeItem,
  Tag,
  Toast
} from 'vant';

import * as adminOrderService from "@/api/admin/order";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Form.name]: Form,
    [Button.name]: Button,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [ContactCard.name]: ContactCard,
    [Tag.name]: Tag,
    [Divider.name]: Divider,
    [DatetimePicker.name]: DatetimePicker,
    [Loading.name]: Loading,
    [PullRefresh.name]: PullRefresh,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [List.name]: List,
    [CheckboxGroup.name]: CheckboxGroup,
    [Image.name]: Image,
    [Notify.name]: Notify,
    [CouponCell.name]: CouponCell,
    [NoticeBar.name]: NoticeBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  name: "DebtList",
  props: ["searchParam"],
  data() {
    return {
      orderInfoPopupShow: false,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      pages: {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      },
      orderInfos: [],
      isPaymentShow: false,
      totalPrice: 0,
      paymentPrice: 0,
      payPrice: 0,
      mark: null,
      paymentStatusChecked: '0',
      paymentTypeChecked: '微信',
      verifySuccess: true,
      debtId: null,
    };
  },
  created() {
    console.log(this.searchParam)
  },
  methods: {
    verifyPayment() {
      // 如果支付状态为付清 则支付金额必须等于订单金额
      // 如果支付状态为欠账 则支付金额必须小于订单金额
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      if (Number(this.payPrice) <= Number(0)) {
        Toast("请输入支付金额")
        this.verifySuccess = false
        return
      }
      if ((Number(this.paymentPrice) + Number(this.payPrice)) > Number(this.totalPrice)) {
        Toast("支付金额不能高于需支付金额")
        this.verifySuccess = false
        return
      }
      // 付清
      if (this.paymentStatusChecked === '1') {
        if ((Number(this.payPrice) + Number(this.paymentPrice)) !== Number(this.totalPrice)) {
          Toast("支付金额要等于订单金额")
          this.verifySuccess = false
          return
        }
      } else if (this.paymentStatusChecked === '0') {
        if ((Number(this.payPrice) + Number(this.paymentPrice)) === Number(this.totalPrice)) {
          Toast("支付金额不能等于订单金额")
          this.verifySuccess = false
          return
        }
      }

      this.verifySuccess = true
    },
    onPayForDebt() {
      this.verifyPayment()
      if (!this.verifySuccess) {
        return
      }
      let paramObj = {
        payPrice: this.payPrice,
        statusOfFront: this.paymentStatusChecked,
        mark: this.mark,
        payType: this.paymentTypeChecked
      }
      console.log(paramObj)
      adminOrderService.payForDebt(this.debtId, paramObj).then(res => {
        this.isPaymentShow = false
        this.onRefresh()
        Notify({type: 'success', message: '操作成功'});
      })
    },
    onPaymentStatusChange() {
      // 选择付清则自动设置全额为支付金额
      // 选择支付状态为结账时则设置支付金额为订单金额
      if (this.paymentStatusChecked === '2' || this.paymentStatusChecked === '1') {
        this.payPrice = Number(this.totalPrice) - Number(this.paymentPrice)
      }
    },
    submitForPayOrder(item) {
      this.totalPrice = item.totalPrice
      this.paymentPrice = item.payPrice
      this.debtId = item.id
      this.isPaymentShow = true
    },
    toDebtInfo(item) {
      this.$router.push({path: '/order/debt/info/' + item.id})
    },
    deleteDebt(item) {
      if (item.type === 0) {
        Dialog.confirm({
          title: '此账单关联订单也会被删除! 确认删除此账单吗？',
          message: '删除此账单后，无法找回！',
        })
            .then(() => {
              adminOrderService.deleteAccountOrder(item.orderId).then(res => {
                if (res.status === 200) {
                  Notify({type: 'success', message: '删除成功'});
                  this.onRefresh()
                } else {
                  Notify({type: 'danger', message: '删除失败'});
                }
              })
            })
      } else {
        Dialog.confirm({
          title: '确认删除此账单吗？',
          message: '删除此账单后，无法找回！',
        })
            .then(() => {
              adminOrderService.deleteAccountDebt(item.id).then(res => {
                Notify({type: 'success', message: '删除成功'});
                this.onRefresh()
              })
            })
      }
    },
    rollBack(item) {
      if (item.type === 0) {
        Dialog.confirm({
          title: '确认回滚此账单吗？',
          message: '确认回滚此账单吗?',
        }).then(() => {
          adminOrderService.rollbackSingle(item.id).then(res => {
            this.$toast('回滚成功')
            this.onRefresh()
          })
        })
      } else {
        Dialog.confirm({
          title: '确认回滚此账单吗？',
          message: '确认回滚此账单吗?',
        }).then(() => {
          adminOrderService.rollback(item.id).then(res => {
            this.$toast('回滚成功')
            this.onRefresh()
          })
        })
      }
    },
    toOrderIndo() {
    },
    getAccountOrderInfo(orderId) {
      adminOrderService.getAccountOrderInfo(orderId).then(res => {
        if (res.status === 200) {
          this.orderInfos = res.data
        }
      });
      this.orderInfoPopupShow = true;
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
      // let param = 'page=' + this.pages.currPage + '&limit=10';
      this.searchParam.page = this.pages.currPage
      this.searchParam.limit = 10
      // if (this.orderInfo.userId !== undefined) {
      //   param += '&accountUserId=' + this.orderInfo.userId;
      // }
      let that = this;
      adminOrderService.getDebtPage(this.searchParam).then(res => {
        if (res.status === 200) {
          that.dataList = that.dataList.concat(res.data.records)
          this.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          console.log(this.pages)
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }

          console.log(that.dataList)
        }
      });
    },
    onRefresh() {
      console.log('父亲刷新我')
      console.log(this.searchParam)
      // 清空列表数据
      this.finished = false;
      this.dataList = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.onLoad();
    },
    resetData() {
      this.pages = {
        perPage: 8,
        currPage: 1,
        pageCount: 3
      };
      this.loading = false;
      this.finished = false;
      this.refreshing = false;
    },
  },
};
</script>

<style lang="less">
.notice-swipe {
  height: 30px;
  line-height: 30px;
}

.van-contact-card1 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}

.van-contact-card1::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.van-contact-card2::before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
  background-size: 80px;
  content: '';
}

.custom-title {
  margin-right: 4px;
  vertical-align: middle;
  display: flex;
  margin-left: 3px;
}

.search-icon {
  font-size: 16px;
  line-height: inherit;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}


.address-popup-load {
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}

.va-tag-su {
  margin-left: 2px
}

.order-tag-margin-left {
  margin-left: 2px
}
</style>
